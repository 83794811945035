<template>
  <div
    class="app-content-wrapper apps-wrapper shadow-lg"
    style="box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);"
  >
    <div class="app-content relative">
      <div
        class="app-icon pa-4"
        @click="appToggle"
      >
        <v-icon color="black">
          mdi-menu-open
        </v-icon>
      </div>
      <vue-perfect-scrollbar
        :settings="{suppressScrollX: true, wheelPropagation: false}"
        class="h-100 rtl-ps-none ps scroll"
        style="height:100%"
      >
        <div class="app-body pt-0 px-8">
          <v-row>
            <v-col
              cols="12"
              class=""
            >
              <v-dialog
                v-model="dialog"
                persistent
                max-width="600px"
              >
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                    class="rounded-lg py-5"
                    tile
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      left
                      class="pr-2"
                    >
                      mdi-link
                    </v-icon>
                    {{$t('general.add_link')}}
                  </v-btn>
                </template>
                <v-card class="">
                  <v-card-title>
                    <span class="text-h5">{{$t('general.add_link')}}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                        >
                          <v-text-field
                            v-model="newLink.title"
                            :label="'* '+$t('general.title')"
                            :error-messages="titleErrors"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                        >
                          <v-text-field
                            v-model="newLink.link"
                            :label="'* '+$t('general.link')"
                            :error-messages="linkErrors"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <label for="">{{$t('general.link_color')}}</label>
                          <v-color-picker
                            v-model="newLink.color"
                            class="ma-2"
                            hide-canvas
                            show-swatches
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                    <small>*{{$t('general.required_field')}}</small>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary darken-1"
                      text
                      @click="dialog = false"
                    >
                      {{$t('general.cancel')}}
                    </v-btn>
                    <v-btn
                      color="primary darken-1"
                      text
                      @click="setLink"
                    >
                      {{$t('general.save')}}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col
              v-for="(link, index) in links"
              :key="index"
              cols="12"
              md="12"
              sm="6"
              lg="6"
            >
              <base-card>
                <v-alert
                  border="top"
                  colored-border
                  :color="link.color"
                >
                  <div class="card-title">
                    {{ link.title }}
                  </div>
                  <div class="d-flex justify-space-between">
                    <div>
                      <v-btn
                        icon
                        color="danger"
                        @click="deleteLink(link.id)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                    <div>
                      <v-btn
                        icon
                        color="primary"
                        :href="link.link"
                        target="_blank"
                      >
                        <v-icon>mdi-link-variant</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-alert>
              </base-card>
            </v-col>
          </v-row>
        </div>
      </vue-perfect-scrollbar>
    </div>
  </div>
</template>
<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import { get, post, del } from '../../../worker/worker-api'
  import { mapGetters } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { required  } from 'vuelidate/lib/validators'

  export default {
    name: 'Link',
    mixins: [validationMixin],
    metaInfo() {
      return{
        // title will be injected into parent titleTemplate
        title: this.$t('general.link_list'),
      }
    },
    props: {
      // AdressRolleID from Child
      childId: {
        type: Number,
        default: 0
      }
    },
    components: {
      VuePerfectScrollbar,
    },
    data: () => ({
      dialog: false,
      isOpen: false,
      isBlock: false,
      links: [],
      newLink: {},
      title: '',
      link: '',
      color: '',
      // item: 1,
      // itemsTwo: 0,
      drawer: null,
      drawerArray: [
        { title: 'Home', icon: 'dashboard' },
        { title: 'About', icon: 'question_answer' },
      ],
      notes: [],
      items: [
        { text: 'Alle Links', icon: 'mdi-clipboard-list-outline' },
        { text: 'Favourites', icon: 'mdi-star' },
        { text: 'Personal', icon: 'mdi-bag-personal' },
        { text: 'Work', icon: 'mdi-account-clock' },
      ],
      i_AddressRoleID: null,
    }),
    beforeMount () {
      if (this.getImpersonation?.b_Status) this.i_AddressRoleID = this.getImpersonation.i_AddressRoleID
      else this.i_AddressRoleID = this.$smt.getAddressRoleID(this.$route.meta.role)
      this.getLinks()
    },
    computed: {
      titleErrors () {
        const errors = []
        if (!this.$v.newLink.title.$dirty) return errors
        !this.$v.newLink.title.required && errors.push(this.$t('general.required_field'))
        return errors
      },
      linkErrors () {
        const errors = []
        if (!this.$v.newLink.link.$dirty) return errors
        !this.$v.newLink.link.required && errors.push(this.$t('general.required_field'))
        return errors
      },
    },
    methods: {
      getLinks() 
      {
        if(this.childId != 0){
          this.i_AddressRoleID = this.childId;
        }// if
        if (this.i_AddressRoleID === null) return
        get(`${process.env.VUE_APP_SMT_API_URL}/data/user/${this.i_AddressRoleID}/link`, {
        })
          .then(response => { 
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.links = response;
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      setLink() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        if(this.childId != 0){
          this.i_AddressRoleID = this.childId;
        }// if
        post(`${process.env.VUE_APP_SMT_API_URL}/data/user/${this.i_AddressRoleID}/link`, this.newLink)
          .then(response => { 
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.links.push({
              title: this.newLink.title,
              link: this.newLink.links,
              color: this.newLink.color.hex,
            });
            this.dialog = false
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      deleteLink(Ai_LinkID) {
        if(this.childId != 0){
          this.i_AddressRoleID = this.childId;
        }// if
        del(`${process.env.VUE_APP_SMT_API_URL}/data/user/${this.i_AddressRoleID}/link/${Ai_LinkID}`, {})
        .then(response => { 
          if (response.status === 401) {
            this.signOut()
            this.$smt.logout()
            return
          }
          this.links = this.links.filter((link) => link.id !== Ai_LinkID)
        })
        .catch(error => {
          console.log('Worker error: ', error)
        })
      },

      appToggle: function () {
        this.isOpen = !this.isOpen
        this.isBlock = !this.isBlock
      },
      overlayApp: function () {
        this.isOpen = !this.isOpen
        this.isBlock = !this.isBlock
      },
    },
    validations: {
      newLink: {
        title: { required },
        link: { required }
      }
    },
  }
</script>
<style lang="scss" scoped>
.app-content-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    height: calc(100vh - 200px);

    .app-overlay {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        display: none;
        z-index: 2;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            &.open {
                display: block;
                transition: all 0.3s ease-in;
            }
        }
    }
    .app-sidebar {
        width: 280px;
        position: relative;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            position: absolute;
            left: -340px;
            z-index: 5;
            height: calc(100vh - 120px);
            transition: all 0.3s ease-in;
            &.open {
                left: 0;
            }
        }
        .app-sidebar-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-sidebar-body {
            // overflow-y: scroll;
            // height: 100%;
            padding-top: 3.375rem;
            width: 280px;
        }
    }

    .app-content {
        width: calc(100% - 280px);
        @media only screen and (max-width: 959px) {
            width: 100%;
        }
        // margin-left: 30px;
        .app-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-body {
            flex: 1 1 auto;
            padding-top: 3.375rem;
            // overflow-y: scroll;
            height: calc(100% - 130px);
        }
        .app-footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 130px;
        }
    }
}
.app-icon {
    display: none;
    @media only screen and (max-width: 959px) {
        display: block;
    }
}
.eg-filemanager {
    background-color: #fff;
}
.filemanager-content {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.apps-wrapper {
    &.--filemanager {
        .nested-sidebar-toggle {
            @media (min-width: 959px) {
                display: none;
            }
        }
    }
}
</style>
